import React, { useState, useEffect } from 'react';

const AaddyyBoutique = () => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [currentImageIndices, setCurrentImageIndices] = useState([0, 1]);
  const [fade, setFade] = useState(true); // Controls the fade-in and fade-out effect

  // This would typically be fetched from the server or read dynamically
  const imageFiles = [
    '1.png',
    '2.png',
    '3.png',
    '4.png',
    '5.png',
    '6.png',

    // Add more image filenames as needed
  ];

  useEffect(() => {
    // Simulate initial loading
    const timer = setTimeout(() => {
      setLoading(false);
      setImages(imageFiles.map(file => `/library/${file}`));
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Rotate images every 3 seconds with a fade effect
    const rotateInterval = setInterval(() => {
      // Start fade-out
      setFade(false);

      setTimeout(() => {
        // After fade-out, update the images and start fade-in
        setCurrentImageIndices(prevIndices => {
          return prevIndices.map(index => (index + 2) % images.length);
        });
        setFade(true); // Start fade-in after changing images
      }, 500); // 500ms matches the CSS transition time for fade-out
    }, 5000);

    return () => clearInterval(rotateInterval);
  }, [images]);

  if (loading) {
    return (
      <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#fffcf6" }}>
        <img src="/library/logo1.png" alt="AADDYY Logo" className="img-load-view" style={{ width: "10%" }} />
      </div>
    );
  }

  return (
    <div className="container py-2">
      {/* <h1 className="display-4 text-center mb-3">AADDYY</h1> */}
      <div className="text-center">
        <img src="/library/logo1.png" className='img-fluid ' alt="" style={{ height: "10vh" }} />
        <p className="lead text-center mb-5 mt-2" style={{ fontFamily: "Inria Serif" }}>AI Boutique for Premium graphics & Fashion Designing</p>
      </div>

      <div className="row mb-5">
        {currentImageIndices.map((imageIndex, index) => (
          <div key={index} className="col-md-6 mb-4 mb-md-0">
            <img
              src={images[imageIndex]}
              alt={`Fashion Image ${index + 1}`}
              className="img-fluid  img-frontpage  object-fit-cover"
              style={{
                transition: 'opacity 0.5s ease-in-out', // Transition effect for fade
                opacity: fade ? 1 : 0, // Control the fade based on state
                height: "60vh"
              }}
            />
          </div>
        ))}
      </div>

      <p className="lead text-center mb-4" style={{ fontFamily: "Inria Serif" }}>Connect with Boutique</p>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-outline-dark mx-2 border-0"
          style={{
            fontFamily: "Inria Serif",
            backgroundColor: "rgba(232, 232, 232, 1)",
            display: "flex",
            alignItems: "center",
            gap: "10px"
          }}
          onClick={() => window.location.href = 'mailto:aaddyygraphics@gmail.com'}
        >
          <i className="fa-regular fa-envelope"></i> Email
        </button>

        <a
          className="btn btn-outline-dark mx-2 border-0"
          href='https://www.instagram.com/aaddyy.official/'
          style={{
            fontFamily: "Inria Serif",
            backgroundColor: "rgba(232, 232, 232, 1)",
            display: "flex",
            alignItems: "center",
            gap: "10px"
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-instagram"></i> Instagram
        </a>
      </div>
    </div>
  );
};

export default AaddyyBoutique;